import { useContext } from 'react';
import classes from './Facing.module.css';
import LanguagePicker from './LanguagePicker';
import video from './bg.mp4';
import { LanguageContext } from '../LanguageContext/LanguageContext';
import Logo from '../Logo/Logo';

function Facing() {
    const { language } = useContext(LanguageContext);

    const scrollToDetailed = () => {
        const element = document.getElementById('call-me-section');
        if (element !== null) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const scrollToGallery = () => {
        const element = document.getElementById('gallery-section');
        if (element !== null) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const translations = {
        ru: {
            main_title: "ОЛИВКОВОЕ ДЕРЕВО — ЛИКВИДНОСТЬ ВНЕ ВРЕМЕНИ",
            btn: "УЗНАТЬ БОЛЬШЕ"
        },
        en: {
            main_title: "OLIVE TREE - TIMELESS LIQUIDITY",
            btn: "DISCOVER MORE"
        }
    }
    return (
        <div>
            <div className={classes.contentContainer}>
                <div className={classes.navContainer}>
                    <div className={classes.logo}>
                        <Logo /> Olive Trees
                    </div>
                    <div onClick={scrollToGallery} className={classes.link}>Галерея</div>
                    <div onClick={scrollToDetailed} className={classes.link}>О нас</div>
                </div>
            </div>
        </div>
    )
}

export default Facing;