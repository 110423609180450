import React, { useContext } from 'react';
import classes from './DetailedInfo.module.css';
import { LanguageContext } from '../LanguageContext/LanguageContext';

function DetailedInfo(props) {
    const { language, showOffer } = useContext(LanguageContext);

    const translations = {
        ru: {
            title: "О нас",
            description: "Покупая сертификат на оливковое дерево через приложение \"OliveTrees\", вы получаете уникальную возможность стать причастным к сохранению флоры нашей планеты Земля!",
            so: "Кроме этого, мы:",
            st: "владеем большим банком земли для организации плантаций нашим партнерам",
            nd: "регулярно получаем рекомендации и передовые технологии которые внедряем в наш бизнес",
            rd: "имеем огромную поддержку государства в виде различных субсидий и помощи в обслуживании плантаций",
            p1: "После успешной покупки вы получите электронный сертификат, в котором указаны все данные о дереве которое высажено, включая год посадки, геоданные и уникальный код.Этот сертификат станет своего рода \"билетом\" к вашему посаженому дереву.",
            p2: "Но это еще не все! Каждый владелец сертификата на оливковое дерево будет иметь возможность приехать как эко- турист в оливковый сад где растет его дерево.Поучаствовать в уходе за деревьями.Принимать участие в празднике сбора урожая.Почувствовать себя причастным к озеленению планеты и увековечить себя на планете так как оливковое дерево растет больше 1000 лет.",
            p3: "Покупая сертификат в приложении \"OliveTrees\", вы поддерживаете экологию, ведь каждое оливковое дерево производит кислорода для дыхания 2-х людей на планете.Будьте уверены, что ваша покупка будет иметь большое значение для нашей компании и окружающей среды.",
            p4: "Присоединяйтесь к нашему сообществу, которое озеленяет планету уже сейчас!",
            title1: "Одно дерево",
            desc1: "Кислород для Двух человек на планете!",
            title2: "Деревья",
            desc2: "Растут сотни лет!",
            title3: "Вечнозеленое дерево",
            desc3: "Фотосинтез оливы не прекращается круглый год.",
            title4: "Мониторинг",
            desc4: "Контролируйте сокращение углеродного следа в приложении!",
            getOffer: "Получить предложение"
        },
        uk: {
            title: "Ми - агрокомплекс, який займається організацією та подальшим обслуговуванням промислових оливкових плантацій.",
            description: "Ми забезпечуємо вам доступ до передових практик і технологій. Наша команда співпрацює з провідними інститутами та експертами з Туреччини, Іспанії та інших представників Міжнародної організації оливкової оливи (IOC), щоб гарантувати успішний розвиток вашої плантації.",
            so: "Крім цього, ми:",
            st: "володіємо великим земельним банком для організації плантацій нашим партнерам",
            nd: "регулярно отримуємо рекомендації та передові технології, які впроваджуємо у наш бізнес",
            rd: "маємо велику підтримку держави у вигляді різних субсидій та допомоги у обслуговуванні плантацій",
            p1: "Грузія має великий потенціал для розвитку завдяки своєму унікальному клімату.",
            p2: "Як наш партнер, ви зможете скористатися перевагами цього потенціалу, оскільки ваші активи будуть професійно обслуговуватися та приносити стабільний пасивний дохід.",
            p3: "Приєднуйтесь до нас і забезпечте своє фінансове майбутнє, отримуючи стабільний джерело доходу.",
            p4: "Приєднуйтесь до нас і забезпечте своє фінансове майбутнє, отримуючи стабільний джерело доходу.",
            title1: "400+ га",
            desc1: "оливкових плантацій налічує наше господарство",
            title2: "Деревья",
            desc2: "Растут сотни лет",
            title3: "John Deеr",
            desc3: "є нашим офіційним партнером в Грузії",
            getOffer: "Отримати пропозицію"
        },
        en: {
            title: "We are an agro complex specializing in the organization and maintenance of industrial olive plantations.",
            description: "We provide you with access to advanced practices and technologies. Our team collaborates with leading institutions and experts from Turkey, Spain, and other representatives of the International Olive Council (IOC) to ensure the successful development of your plantation.",
            so: "In addition, we:",
            st: "own a large land bank for organizing plantations for our partners",
            nd: "regularly receive recommendations and implement cutting-edge technologies in our business",
            rd: "have extensive government support in the form of various subsidies and plantation maintenance assistance",
            p1: "Georgia has tremendous potential for development due to its unique climate.",
            p2: "As our partner, you will be able to leverage this potential as your assets are professionally serviced and generate stable passive income.",
            p3: "Join us and secure your financial future by gaining a stable source of income.",
            p4: "Присоединяйтесь к нашему сообществу, которое озеленяет планету уже сейчас!",
            title1: "One tree",
            desc1: "Oxygen for two people on the planet!",
            title2: "Trees",
            desc2: "Growing for decades!",
            title3: "",
            desc3: "",
            getOffer: "Get the offer"
        }
    }

    const scrollToOffer = () => {
        showOffer();
        const element = document.getElementById('offer-section');
        if (element !== null) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    return (
        <div className={classes.container} id="call-me-section">
            <div className={classes.holder}>
                <div className={classes.content}>
                    <span className={classes.title}>{translations[language].title}</span>
                    <div className={classes.separator}>
                        <div className={classes.ftpart}>
                            <div>
                                <span>{translations[language].description}</span>
                            </div>
                            {/* <div>
                                <span>{translations[language].so}</span>
                                <div className={classes.list}>
                                    <div>{translations[language].st}</div>
                                    <div>{translations[language].nd}</div>
                                    <div>{translations[language].rd}</div>
                                </div>
                            </div> */}
                            <div className={classes.secondary}>
                                <div>{translations[language].p1}</div>
                                <div>{translations[language].p2}</div>
                                <div>{translations[language].p3}</div>
                                <div>{translations[language].p4}</div>
                            </div>
                        </div>
                        <div className={classes.ndpart}>
                            <div className={classes.cardContainer}>
                                <div>
                                    <div className={classes.cardTitle}>{translations[language].title1}</div>
                                    <div className={classes.cardDesc}>{translations[language].desc1}</div>
                                </div>
                                <div>
                                    <div className={classes.cardTitle}>{translations[language].title2}</div>
                                    <div className={classes.cardDesc}>{translations[language].desc2}</div>
                                </div>
                                <div>
                                    <div className={classes.cardTitle}>{translations[language].title3}</div>
                                    <div className={classes.cardDesc}>{translations[language].desc3}</div>
                                </div>
                                <div>
                                    <div className={classes.cardTitle}>{translations[language].title4}</div>
                                    <div className={classes.cardDesc}>{translations[language].desc4}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className={classes.buttonHolder}>
                        <div onClick={scrollToOffer} className={classes.button}>
                            {translations[language].getOffer}
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default DetailedInfo;