import './App.css';
import Logo from './Logo/Logo';
import AnyQuestions from './components/AnyQuestions/AnyQuestions';
import CardsSection from './components/CardsSection/CardsSection';
import Description from './components/Description/Description.tsx';
import DetailedInfo from './components/DetailedInfo/DetailedInfo';
import Download from './components/Download/Download';
import Facing from './components/Facing/Facing';
import Footer from './components/Footer/Footer';
import Gallery from './components/Gallery/Gallery';
import GetOffer from './components/GetOffet/GetOffer';
import { LanguageProvider } from './components/LanguageContext/LanguageContext';
import AboutUs from './components/MainTextSectionAboutUs/AboutUs';
import Progress from './components/Progress/Progress';
import StockCottages from './components/StockCottages/StockCottages';
import Pdf from '../src/files/privacy.pdf';
import Pdf2 from '../src/files/privacy2.pdf';
import "smoothscroll-for-websites";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<>
          <LanguageProvider>
            <div className="main-container">
              <Facing />
              <Description />
              <CardsSection />
              {/* <AboutUs /> */}
              <Gallery />
              <StockCottages />
              <DetailedInfo />
              {/* <AnyQuestions /> */}
              {/* <GetOffer /> */}
              <Download />
              <Footer />
              <Progress />
            </div>
          </LanguageProvider>
        </>}>
        </Route>
        <Route path="/policy" element={<div style={{ width: '100vw', height: '100vh' }}>
          {/* <divasd></div> */}
          <object style={{ width: '100vw', height: '100vh' }} data={Pdf} type="application/pdf">   </object>
        </div>} />
        <Route path="/privacy-policy-2" element={<div style={{ width: '100vw', height: '100vh' }}>
          {/* <divasd></div> */}
          <object style={{ width: '100vw', height: '100vh' }} data={Pdf2} type="application/pdf">   </object>
        </div>} />
      </Routes>
    </Router>
  );
}

export default App;
