import React, { useContext } from 'react';
import play from '../../images-1/play.png'
import app from '../../images-1/app.png'
import classes from './Download.module.css';
import { LanguageContext } from '../LanguageContext/LanguageContext';

function Download(props) {
    const { language } = useContext(LanguageContext);


    const translations = {
        ru: {
            greeting: "Наше приложение на разных платформах",
        },
        uk: {
            greeting: "Відкрийте для себе нові обрії для пасивного доходу",
        },
        en: {
            greeting: "Download our application",
        }
    }
    return (
        <div className={classes.container}>
            <div className={classes.holder}>
                <div className={classes.content}>
                    {translations[language].greeting}
                    <div className={classes.contentAdd}>
                        <a href="https://play.google.com/store/apps/details?id=com.crustbrns.biodeposit_mobile" target="_blank">
                            <img className={classes.app} src={play} />
                        </a>
                        <a href="https://apps.apple.com/us/app/olivetrees/id6449684717" target="_blank">
                            {/* <BsInstagram className={classes.inst} style={{ fontSize: 30 }} /> */}
                            <img className={classes.app} src={app} />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Download;