import classes from './Gallery.module.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React, { useContext } from 'react';
import Slider from "react-slick";
import { RiMapPinLine } from 'react-icons/ri';
import { TbOlympics } from 'react-icons/tb';
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

import plant1 from '../../images-1/plants/1.jpeg';
import plant2 from '../../images-1/plants/2.jpg';
import plant3 from '../../images-1/plants/3.jpeg';
import plant4 from '../../images-1/plants/4.jpg';
import plant5 from '../../images-1/plants/5.jpg';
import plant6 from '../../images-1/plants/6.MP4';

import { LanguageContext } from '../LanguageContext/LanguageContext';

var settings = {
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0
};


function Gallery() {
    const { language, showOffer, detailed } = useContext(LanguageContext);

    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth
    })

    const translations = {
        ru: {
            title1: "Оливковый бизнес - один из самых прибыльных и стабильных в агросекторе",
            desc1: "Так выглядит ваш актив до и после первого урожая",
            title2: "Оливковые плантации",
            desc2: "Вы можете увидеть высаженные деревья которые уже озеленяют планету",
            championship: "Чемпионата мира по фристайлу 2023",
            and: " и ",
            festival: "Европейского зимнего юношеского олимпийского фестиваля 2025.",
            such: " Такие мероприятия обеспечивают ликвидность активов и их стремительный рост.",
            offer: "Выбрать коттедж"
        },
        en: {
            title1: "The olive business is one of the most profitable and stable in the agricultural sector",
            desc1: "This is what your asset looks like before and after the first harvest",
            title2: "Olive trees",
            desc2: "You can see the planted trees that are already greening the planet",
            championship: "the Freestyle World Championships 2023",
            and: " and ",
            festival: "the European Winter Youth Olympic Festival 2025.",
            such: " Such events provide asset liquidity and their rapid growth.",
            offer: "Choose the cottage"
        }
    }


    const imagesCottages = [
        {
            original: plant1,
            thumbnail: plant1,
            originalWidth: window.innerWidth,
            originalHeight: 300,
            thumbnailHeight: 60
        },
        {
            original: plant2,
            thumbnail: plant2,
            originalWidth: window.innerWidth,
            originalHeight: 300,
            thumbnailHeight: 60
        },
        {
            original: plant3,
            thumbnail: plant3,
            originalWidth: window.innerWidth,
            originalHeight: 300,
            thumbnailHeight: 60
        },
        {
            original: plant4,
            thumbnail: plant4,
            originalWidth: window.innerWidth,
            originalHeight: 300,
            thumbnailHeight: 60
        },
        {
            original: plant5,
            thumbnail: plant5,
            originalWidth: window.innerWidth,
            originalHeight: 300,
            thumbnailHeight: 60
        }
    ]

    const [indexCottages, setIndexCottages] = React.useState(0);
    const [indexPlants, setIndexPlants] = React.useState(0);
    const [indexGallery, setIndexGallery] = React.useState(0);

    React.useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth
            })
        }
        window.addEventListener('resize', handleResize);

        return _ => {
            window.removeEventListener('resize', handleResize);
        }
    })

    const scrollToOffer = () => {
        showOffer();
        const element = document.getElementById('offer-section');
        if (element !== null) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    function getButton() {
        if (!detailed) {
            return <div className={`${classes.buttonHolder}`}>
                <div className={classes.buttonform} onClick={scrollToOffer}>{translations[language].offer}</div>
            </div>
        }
    }

    function getPlantsGallery() {
        if (window.innerWidth > 768) {
            // return <Slider afterChange={function (e) { setIndexPlants(e) }} autoplay={false} autoplaySpeed={4000} arrows={dimensions.width > 768 ? true : false}  {...settings}>
            //     <img className={classes.imageContainerAdvanced} src={plant1} />
            //     <img className={classes.imageContainerAdvanced} src={plant2} />
            //     <img className={classes.imageContainerAdvanced} src={plant3} />
            //     <img className={classes.imageContainerAdvanced} src={plant5} />
            //     <img className={classes.imageContainerAdvanced} src={image27} />
            //     <img className={classes.imageContainerAdvanced} src={image28} />
            //     <img className={classes.imageContainerAdvanced} src={image29} />
            //     <img className={classes.imageContainerAdvanced} src={image30} />

            //     <video controls autoPlay muted loop className={classes.imageContainerAdvanced}>
            //         <source src={plant6} type='video/mp4' />
            //     </video>
            // </Slider>
        }
        return <>
            {/* <ImageGallery items={imagesOlives} /><br /> */}
        </>
    }

    function getCottagesGallery() {
        if (window.innerWidth > 768) {
            return <Slider afterChange={function (e) { setIndexCottages(e) }} autoplay={false} autoplaySpeed={4000} arrows={dimensions.width > 768 ? true : false} {...settings}>
                <img className={classes.imageContainerAdvanced} src={plant1} />
                <img className={classes.imageContainerAdvanced} src={plant2} />
                <img className={classes.imageContainerAdvanced} src={plant3} />
                <img className={classes.imageContainerAdvanced} src={plant4} />
                <img className={classes.imageContainerAdvanced} src={plant5} />
                <video controls autoPlay muted loop className={classes.imageContainerAdvanced}>
                    <source src={plant6} type='video/mp4' />
                </video>
            </Slider>
        }
        return <>
            <ImageGallery items={imagesCottages} /><br />
        </>
    }

    return (
        <div className={classes.container} id="gallery-section">
            {/* <div className={`${classes.gallerySectionHolder}`}>
                <div className={classes.gallerySection} style={{ color: "#2E2C2C" }}>
                    <div className={`${classes.titleHolder} ${classes.marg}`}>
                        <div className={classes.galleryHolder}>
                            <RiMapPinLine onClick={() => window.open("https://goo.gl/maps/AcBdgyooZggr984a6?coh=178571&entry=tt")} className={classes.pin} style={{ fontSize: 80, color: "#2E2C2C" }} /><span className={classes.galleryTitle}>{translations[language].title1}</span>
                        </div>
                    </div>
                    <div className={`${classes.titleHolder} ${classes.titleDesc}`}>
                        {translations[language].desc1}
                    </div>
                    <div className={classes.galleryContainer}>
                        {getPlantsGallery()}
                    </div>
                </div>
            </div> */}
            <div className={`${classes.gallerySectionHolder} ${classes.second}`}>
                <div className={classes.gallerySection}>
                    <div className={`${classes.titleHolder} ${classes.addHolder} ${classes.marg}`}>
                        <div className={classes.galleryHolder}>
                            {/* <RiMapPinLine onClick={() => window.open("https://www.google.com/maps/place/Bakuriani,+Georgien/@41.7469131,43.5073183,14z/data=!3m1!4b1!4m6!3m5!1s0x404316197e7e1f63:0x74a1b8db49722b50!8m2!3d41.7509723!4d43.5291848!16zL20vMDVjNGI3")} className={classes.pin} style={{ fontSize: 50 }} /> */}
                            <span className={classes.galleryTitle}>{translations[language].title2}</span>
                        </div>
                        {/* <div>
                            <TbOlympics className={classes.icond} style={{ fontSize: 50 }} />
                        </div> */}
                    </div>
                    <div className={`${classes.titleHolder} ${classes.titleDesc}`} style={{ marginTop: 15, display: 'flex' }}>
                        <span>
                            {translations[language].desc2}
                            {/* <a href="https://www.youtube.com/watch?v=GitP14BbjcA&ab_channel=FISSnowboarding" target="_blank" className={classes.link}>{translations[language].championship}</a>{translations[language].and}<a href="https://1tv.ge/news/saqartvelos-premier-ministrma-da-evropis-olimpiuri-komitetis-prezidentma-2025-wlis-zamtris-akhalgazrduli-olimpiuri-festivalis-mosamzadebeli-procesi-ganikhiles/" className={classes.link}>{translations[language].festival}</a>{translations[language].such} */}
                        </span>
                    </div>
                    <div className={classes.galleryContainer}>
                        {getCottagesGallery()}
                    </div>
                </div>
                {/* {getButton()} */}
            </div>
        </div>
    )
}
export default Gallery;