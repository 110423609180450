import { useContext } from 'react';
import { LanguageContext } from '../LanguageContext/LanguageContext';
import Card from './Card/Card';
import classes from './CardsSection.module.css';

function CardsSection() {
    const { language } = useContext(LanguageContext);

    const translations = {
        ru: {
            main_title: "С нами вы получаете",
            st_card_title: "Уникальную возможность",
            st_card_description: "Озеленить планету в один клик!\nПокупая сертификат на оливковое дерево вы сокращаете свой углеродный след",
            nd_card_title: "Эко-туризм",
            nd_card_description: "Посещение плантаций отрыто для владельцев сертификатов. \nКаждый владелец может принять участие в уходе за деревьями.",
            rd_card_title: "Вековое дерево",
            rd_card_description: "Оливковые деревья растут сотни и даже тысячи очищая окружающую среду от углекислого газа.\nКупив сертификат вы увековечиваете память о вас в дереве.",
            fo_card_title: "Покупая сертификат",
            fo_card_description: "У вас есть возможность принять участие в эко-форумах.",
        },
        uk: {
            main_title: "З нами ви отримуєте",
            st_card_title: "Високий дохід",
            st_card_description: "Дохід від плантацій розпочнеться з 4-го року експлуатації, а дохідність від котеджів очікується з 2025 року.",
            nd_card_title: "Стабильність поза часом",
            nd_card_description: "Оливкові дерева – унікальний актив, що має автономну капіталізацію. Вони живуть сотні років та зберігають продуктивність протягом усього цього часу.",
            rd_card_title: "Повний цикл обслуговування",
            rd_card_description: "Ми беремо на себе обслуговування плантації та котеджу, дозволяючи вам без турбот отримувати пасивний дохід."

        },
        en: {
            "main_title": "With us you get",
            "st_card_title": "High Yield",
            "st_card_description": "Plantation yield starts from the 4th year of operation.",
            "nd_card_title": "Experience and Professionalism",
            "nd_card_description": "We have been engaged in olive farming since 2015 and are familiar with all the intricacies of this business.",
            "rd_card_title": "Olive Variety",
            "rd_card_description": "We use 5 varieties for cross-pollination, namely: Ayvalik, Gemlik, Izmir, Sofralik, and Pikual."
        }
    }

    return (
        <div className={classes.container}>
            <div className={classes.holder}>
                <div className={classes.content}>
                    <div className={classes.titleContainer}>
                        <div className={classes.title}>{translations[language].main_title}</div>
                    </div>
                    <div className={classes.text}>
                        {/* <Card title={translations[language].st_card_title} desc={translations[language].st_card_description} /> */}
                        <Card title={translations[language].st_card_title} desc={translations[language].st_card_description} />
                        <Card title={translations[language].nd_card_title} desc={translations[language].nd_card_description} />
                        <Card title={translations[language].rd_card_title} desc={translations[language].rd_card_description} />
                        <Card title={translations[language].fo_card_title} desc={translations[language].fo_card_description} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardsSection;