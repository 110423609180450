import React from 'react';
import classes from '../Facing/Facing.module.css';
import { LanguageContext } from '../LanguageContext/LanguageContext';
import { useContext } from 'react';
import image from '../../images-1/facing/face-land.png';
import ArrowRight from '../SVGs/ArrowRight';
import { BsFillPlayFill } from 'react-icons/bs';

function Description(props: any) {
    const { language } = useContext(LanguageContext);

    const scrollToBusiness = () => {
        const element = document.getElementById('call-me-section');
        if (element !== null) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const translations = {
        ru: {
            greeting: "Наша цель",
            description: "Сохранение окружающей среды, что неоспорима в современном мире. Высаживание оливковых деревьев играет значимую экологическую роль в поддержании природного равновесия. Вы делаете важный вклад в озеленение нашей планеты, покупая сертификат в приложении \"Olive Trees\".",
            more: "Узнать больше",
            advice: "Посмотрите на предложение с высоты птичьего полета"
        },
        uk: {
            greeting: "Відкрийте для себе нові обрії для пасивного доходу",
            description: "Придбайте 5 гектарів оливкової плантації в серці Грузії всього за 294 000 $ та отримайте власний котедж у клубному селищі",
            more: "Дізнатися більше",
            advice: "Подивіться на пропозицію з висоти пташиного польоту"

        },
        en: {
            greeting: "Perfect opportunity",
            description: "Discover the perfect opportunity to green the planet! By purchasing a certificate for an olive tree through this app, you gain a unique chance to become the owner of a real living tree that grows on our plantation in Georgia.",
            more: "Discover more",
            advice: "See the proposal from a bird's eye view"
        }
    }

    return (
        <div className={classes.container}>
            <div className={classes.holder}>
                <div className={classes.textContainer}>
                    <div className={classes.textHolder}>
                        <div className={classes.title}>{translations[language].greeting}</div>
                        <span className={classes.desc}>{translations[language].description}</span>
                        <div className={`${classes.buttonHolder} ${classes.btndesktop}`}>
                            <div onClick={scrollToBusiness} className={classes.button}>
                                {translations[language].more}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.imageHolder}>
                    <img className={classes.imageContainer} src={image} />
                    {/* <div onClick={() => { window.open('https://www.youtube.com/watch?v=uClIqzADiDc&ab_channel=OliveFamilyClub') }} className={classes.play}><BsFillPlayFill style={{ fontSize: 40 }} /></div> */}
                    {/* <div className={classes.arrow} >
                        <ArrowRight />
                    </div>
                    <span className={classes.textadvice}>{translations[language].advice}</span> */}
                </div>
                {/* <div className={`${classes.buttonHolder} ${classes.btnmobile}`}>
                    <div onClick={scrollToBusiness} className={classes.button}>
                        {translations[language].more}
                    </div>
                </div> */}
            </div>
        </div>
    );
}

export default Description;