import { useContext } from 'react';
import classes from './Footer.module.css';
import { BsInstagram, BsYoutube } from 'react-icons/bs';
import { FaTelegramPlane, FaTwitter } from 'react-icons/fa'
import { LanguageContext } from '../LanguageContext/LanguageContext';
import Logo from '../Logo/Logo';

function Footer() {
    const { language } = useContext(LanguageContext);

    const translations = {
        ru: {
            contacts: "Мы в соцсетях"
        },
        uk: {
            contacts: "Ми у соцмережах"
        },
        en: {
            contacts: "Our socials"
        }
    }

    return (
        <div className={classes.container}>
            <div className={classes.holder}>
                <div className={classes.main}>
                    <div className={classes.navContainer}>
                        <div className={classes.logoContainer}>
                            {/* <Logo />  */}
                            <Logo />
                            Olive Trees
                        </div>
                    </div>
                    <div className={classes.contactsHolder}>
                        <span className={classes.titleContacts}>Почта</span>
                        <span>olivetreesclub@gmail.com</span>
                    </div>
                    <div className={classes.contactsHolder}>
                        <span className={classes.titleContacts}>Местоположение</span>
                        <span>Деревья - Cердце Грузии, в Кахетии</span>
                    </div>
                    <div className={classes.contactsHolder}>
                        <span className={classes.titleContacts}>Контакты</span>
                        <span>+380635946256</span>
                        <span>+995599427509</span>
                    </div>
                    {/* <div className={classes.additional}>
                        <span className={classes.title}>{translations[language].contacts}</span>
                        <div className={classes.contactsHolderIcons}>
                            <div className={classes.iconHolder}>
                                <a href="https://www.instagram.com/bio_cottege/" target="_blank">
                                    <BsInstagram className={classes.inst} style={{ fontSize: 23 }} />
                                </a>
                                <a href="https://www.youtube.com/@OliveFamilyClub" target="_blank">
                                    <BsYoutube className={classes.inst} style={{ fontSize: 30 }} />
                                </a>
                                <FaTelegramPlane className={classes.telegram} style={{ fontSize: 30 }} />
                                <FaTwitter className={classes.twitter} style={{ fontSize: 30 }} />
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Footer;